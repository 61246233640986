.custom {
  font-size: large;
  font-weight: 700;
  margin-bottom: 10px;
}

.warning-text {
  font-variant: small-caps;
  font-weight: bolder;
  background-color: tomato;
  color: black;
}
