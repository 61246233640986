.background-color-red {
    background-color: green;
}

.appointment-row {
    padding-bottom: 40px;

    .start-time {
        position: absolute;
        top: 5px;
        right: 0;
    }

    .end-time {
        position: absolute;
        bottom: 30px;
        right: 0;
    }
}