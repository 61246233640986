.fc-cell-content {
  font-size: 14px;
}

.fc-timeline-event {
  height: 2rem;
}

.fc-title {
  overflow-wrap: break-word;
}

.bold {
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
