.qr-view {
    margin: auto;
    h6 {
        text-align: center;
        margin-top: 10px;
    }
    h2 {
        margin-top: 5px;
        text-align: center;
    }
}